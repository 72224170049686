import { Icon } from "#components"
import { VIcon } from "vuetify/components"

export default defineComponent({
  name: 'AppIcon',
  props: {
    name: { type: String, required: true },
    color: { type: String },
    size: { type: [String, Number] },
  },
  render(){
    if(this.name?.includes(':')){
      // this is an iconify icon
      const size = this.size? String(this.size):undefined
      return <Icon name={this.name} size={size} />
    }

    // this is local vuetify icon
    return (
      <span>
        <VIcon color={this.color} size={this.size}>{this.name}</VIcon>
      </span>
    )
  }
})